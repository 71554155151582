import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'

import security from '../images/services/security.jpg'


import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'





import webdev from '../images/services/new/banner-software-development-ezgif.com-avif-to-jpg-converter.jpg'
import webapp from '../images/services/new/banner-web-application-development.jpg'
import ecommerce from '../images/services/new/banner-ecommerce-development.jpg'
import consulting from '../images/services/new/banner-software-consulting.jpg'
import mobapp from '../images/services/new/banner-mobile-application-development1-ezgif.com-avif-to-jpg-converter.jpg'

const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | Software Development | Services">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SERVICES</h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={webdev}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Website Development</h3>
                                                <p>In today's digital landscape, where over 66% (5.28 billion) of the global population is connected to the internet, leveraging online platforms becomes imperative. Websites serve as the cornerstone of any online presence strategy. Through strategic implementation aligned with business objectives, they ensure continuous visibility, reach, and effective communication with customers. Our approach encompasses key elements such as strategic planning, user interface and experience design, compelling content creation, and search engine optimization (SEO).
                                                <br/>   <br/> <a class="codered" target='_blank' href="/website-minimum-delivery-scope">Website Delivery Scope</a></p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={webapp}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Web Browser-Based Application Development </h3>
                                                <p>Web browser-based applications offer scalability, seamless access across various devices, centralized deployment and management, and flexibility in deployment options from on-premises to cloud environments. At PragICTS, we specialize in developing enterprise and web-scale applications with a focus on exceptional user experience and robust engineering principles.
</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={ecommerce}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>eCommerce Development</h3>
                                                <p>The global eCommerce market, currently valued at over 6 trillion USD and projected to grow at a CAGR of 8% or more, represents a significant opportunity for businesses. With logistics services becoming more accessible and cost-effective, eCommerce has emerged as a strategic sales channel. PragICTS offers comprehensive eCommerce solutions, including implementation, management, and support, enabling businesses to capitalize on this transformative sales channel. For detailed information, please visit .<a class="codered" href="https://ecommerce.pragits.com" target="_blank">(https://ecommerce.pragits.com)</a></p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={mobapp}>
                                    
                                 <div className="hidden-works-item-text">
                                                <h3>Mobile Application Development</h3>
                                                <p>Mobile applications elevate user experience to the next level, offering performance, security, and convenience. PragICTS specializes in developing mobile applications for both Android and Apple devices, ensuring seamless user interaction and satisfaction.
</p>
                                             
                                                <div className="clearfix"></div>
                                                <span className="serv-number">04.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={consulting}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Software Consulting</h3>
                                                <p>Optimizing the software stack is crucial for businesses seeking to enhance cost efficiency, security, and performance. PragICTS, as a technology-agnostic engineering firm, provides unbiased consulting services to evaluate and optimize your software and IT infrastructure.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                                <span className="serv-number">05.</span>
                                            
                                            </div>
                                        
                                    </div>

                                   

                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
